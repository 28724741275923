import React, { Suspense, useState } from 'react';
import { BrowserRouter, Link, Outlet, Route, Routes, useParams } from 'react-router-dom';
import { RecoilRoot, useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { AccountApp } from './Account';
import './App.css';
import { CreateReducer } from './CreateReducer';
import { CreateStream } from './CreateStream';
import EventApp from './Event';
import { ReducerApp } from './Reducer';
import { ReducersApp } from './Reducers';
import { userRespState } from './state';
import { StyledStreamHeader } from './StreamHeader';


function Root() {
  return (
    <RecoilRoot>
      <Suspense fallback="Loading...">
        <div className="App">
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/:accountName" element={<AccountApp />} />
              <Route path="/:accountName/new" element={<CreateStream />} />
              <Route path="/:accountName/:streamName" element={<StreamLayout />}>
                <Route index element={<EventApp />} />
                <Route path="form" element={<FormApp />} />
                <Route path="reducers" element={<ReducersApp />} />
                <Route path="new" element={<CreateReducer />} />
                <Route path="settings" element={<SettingsApp />} />
              </Route>
              <Route path="/:accountName/:streamName.:reducerName" element={<StreamLayout isReducer={true} />}>
                <Route index element={<ReducerApp />} />
              </Route>
            </Routes>
          </BrowserRouter >
        </div>
      </Suspense>
    </RecoilRoot>
  );
}

function StreamLayout(props: { isReducer?: boolean }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <ErrorBoundary>
        <StyledStreamHeader isReducer={props.isReducer} />
        <Suspense fallback={<LayoutBody>Loading...</LayoutBody>}>
          <Outlet />
        </Suspense>
      </ErrorBoundary>
    </div>
  )
}

export const LayoutBody = styled.div`
  margin-top: 12px;
  margin-left: 26px;
  margin-right: 26px;
`

function Home() {
  const userResp = useRecoilValue(userRespState)
  if (!userResp) {
    return (
      <div>
        <div>
          <Link to="/signup" reloadDocument>Signup</Link></div>
        <div>
          <Link to="/login" reloadDocument>Login</Link>
        </div>
      </div>
    )
  }
  const handleLogout = async () => {
    await fetch('/logout')
    window.location.href = "/"
  }
  return (
    <div>
      <h1>Home</h1>
      <div>{userResp.user.email}</div>
      {userResp.accounts.map(account => (
        <div key={account.id}><a href={`/${account.name}`}>{account.name}</a></div>
      ))}

      <br />
      <div><button onClick={handleLogout}>Logout</button></div>
    </div>
  )
}

function SettingsApp() {
  const { accountName, streamName } = useParams();
  return (
    <div>
      <h1>Settings page</h1>
      <div>Account {accountName}</div>
      <div>Stream {streamName}</div>
    </div>
  )
}

const HTTPFormInput = styled.input`  
  border: none;
  font-size: large;
  font-family: monospace;
  border-bottom: 1px solid #CCC;
  background: #dbdbdb;
  flex-grow: 1;

  :enabled:focus {
    border: none;
    border-bottom: 1px solid #CCC;
    outline: none;
  }
`

function FormApp() {
  const { accountName, streamName } = useParams();
  const [contentType, setContentType] = useState("application/json");
  const [partition, setPartition] = useState("");
  const [eventType, setEventType] = useState("");
  const [data, setData] = useState("");
  const contentLength = data.length

  const onSubmit = async () => {
    const resp = await fetch(`/${accountName}/${streamName}`, {
      method: "POST",
      headers: {
        "Content-Type": contentType,
        "Partition": partition,
        "Event-Type": eventType
      },
      body: data
    })
    const id = await resp.json()

    if (resp.status === 200) {
      window.location.href = `/${accountName}/${streamName}#${id}`
    } else {
      throw new Error("Failed to submit event")
    }
  }
  return (
    <div style={{ marginLeft: 24 }}>
      <h1>HTTP </h1>
      <pre style={{ display: 'flex', flexDirection: 'column', width: 600, height: 500, padding: 20, fontSize: 'large', backgroundColor: '#dbdbdb' }}>
        <div>POST /{accountName}/{streamName} HTTP/1.1</div>
        <div style={{ display: 'flex' }}>Content-Type: <HTTPFormInput value={contentType} onChange={e => setContentType(e.target.value)} /></div>
        <div style={{ display: 'flex' }}>Content-Length: {contentLength}</div>
        <div style={{ display: 'flex' }}>Partition: <HTTPFormInput value={partition} onChange={e => setPartition(e.target.value)} /></div>
        <div style={{ display: 'flex' }}>Event-Type: <HTTPFormInput value={eventType} onChange={e => setEventType(e.target.value)} /></div>
        <div>&nbsp;</div>
        <textarea style={{
          flexGrow: 1,
          resize: 'none',
          border: 'none',
          outline: 'none',
          width: "100%",
          backgroundColor: '#dbdbdb',
          fontSize: 'large',
        }} placeholder="Data" onChange={e => setData(e.target.value)} value={data} />
      </pre>
      <button onClick={onSubmit}>Submit</button>
    </div>
  )
}

class ErrorBoundary extends React.Component<any, { hasError: boolean }> {
  constructor(props: React.PropsWithChildren<{}>) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI)
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

export default Root;