import { atom, atomFamily, selectorFamily } from "recoil";
import { OrgMember, Reducer, Stream, UserResp } from "./models";

const NotFoundError = new Error('Page not found')
export const API_HOST = process.env.REACT_APP_API_SERVER_HOST

export function api(path: string, options: any = {}) {
    return fetch(`${API_HOST}${path}`, {
        ...options,
        credentials: 'include',
        headers: {
            ...options.headers,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(options.body),
    })
}

export const userRespState = atom<UserResp | null>({
    key: 'currentUserState',
    default: (async () => {
        const resp = await api('/user')
        if (resp.status !== 200) {
            return null
        }
        return await resp.json()
    })()
})

export const orgMembersState = atomFamily<OrgMember[], string>({
    key: 'orgMembersState',
    default: async account => {
        const resp = await api(`/members/${account}`)
        if (resp.status !== 200) {
            return []
        }
        return await resp.json()
    }
})

export const currentReducerState = atomFamily<Reducer, [string, string, string]>({
    key: 'currentReducerState',
    default: async ([account, stream, reducer]) => {
        const resp = await api(`/reducers/${account}/${stream}.${reducer}?include=version`)
        if (resp.status !== 200) {
            throw NotFoundError
        }
        return await resp.json()
    }
})

export const reducersListState = atomFamily<Reducer[], [string, string]>({
    key: 'reducersListState',
    default: async ([account, stream]) => {
        const resp = await api(`/reducers/${account}/${stream}`)
        if (resp.status !== 200) {
            throw NotFoundError
        }
        return await resp.json()
    }
})

export const streamsListState = atomFamily<Stream[], string>({
    key: 'streamsListState',
    default: async account => {
        const resp = await api(`/streams/${account}`)
        if (resp.status !== 200) {
            throw NotFoundError
        }
        return await resp.json()
    }
})

export const currentStreamState = atomFamily<Stream, [string, string]>({
    key: 'currentStreamState',
    default: async ([account, stream]) => {
        const resp = await api(`/streams/${account}/${stream}`)
        if (resp.status !== 200) {
            throw NotFoundError
        }
        return await resp.json()
    }
})

interface Event {
    index: number;
    timestamp: number;
    content_type: string;
    identity: string;
    type: string;
    data: any;
}

export const eventState = selectorFamily<Event[], string>({
    key: 'eventState',
    get: streamPath => async () => {
        const resp = await fetch(streamPath, { credentials: 'include' })
        return await resp.json()
    }
})
