import { useState } from "react"
import { useParams } from "react-router-dom"
import { useResetRecoilState } from "recoil"
import { appPath } from "./routes"
import { api, reducersListState } from "./state"

export function CreateReducer() {
    const { accountName, streamName } = useParams()
    if (!accountName || !streamName) {
        throw new Error("Invalid path")
    }
    const [reducerName, setReducerName] = useState("")
    const resetReducers = useResetRecoilState(reducersListState([accountName!, streamName!]))
    const createReducer = async () => {
        const resp = await api(`/reducers/${accountName}/${streamName}`, { method: "POST", body: { name: reducerName } })
        if (resp.status >= 200 && resp.status < 300) {
            resetReducers()
        }
        const reducer = await resp.json()
        console.log("asdf", appPath({ accountName, streamName, reducerName: reducer.name }))
        window.location.href = appPath({ accountName, streamName, reducerName: reducer.name })
    }
    return (
        <div style={{ marginLeft: 30, marginRight: 30, marginTop: 10 }}>
            <div>Create reducer</div>
            <form onSubmit={createReducer}>
                <input type="text" value={reducerName} onChange={e => setReducerName(e.target.value)} autoFocus />
                <button>Submit</button>
            </form>
        </div>
    )
}