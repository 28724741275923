import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { eventState } from "./state";

const CellDiv = styled.div`
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
`

function EventTable() {
  const { accountName, streamName } = useParams()
  const data = useRecoilValue(eventState(`/${accountName}/${streamName}`)) || [];

  return (
    <table style={{ width: '100%', tableLayout: 'fixed', borderSpacing: 0, borderCollapse: 'collapse' }}>
      <thead>
        <tr>
          <th>Index</th>
          <th>Timestamp</th>
          <th>Event Type</th>
          <th>Identity</th>
          <th>Content Type</th>
          <th>Event Data</th>
        </tr>
      </thead>
      <tbody>
        {data.map((event, index) => (
          <tr key={index} style={{ maxWidth: 80, border: '1px solid #F0F0F0' }}>
            <td>{event.index}</td>
            <td>{event.timestamp} </td>
            <td>{event.identity}</td>
            <td>{event.type}</td>
            <td>{event.content_type}</td>
            <td>
              <CellDiv>{JSON.stringify(event.data)}</CellDiv>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default function EventApp() {
  return (
    <div style={{ padding: 16 }}>
      <EventTable />
    </div>
  )
}
