import { Link, useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"
import { Reducer } from "./models"

import { LayoutBody } from "./Root"
import { appPath } from "./routes"
import { reducersListState } from "./state"

export function ReducersApp() {
  const { accountName, streamName } = useParams()
  const reducers = useRecoilValue(reducersListState([accountName!, streamName!])) || [];
  return (
    <LayoutBody>
      <div>
        <Link to={`${appPath({ accountName, streamName })}/new`}>Create new reducer</Link>
      </div>
      <ul>
        {reducers.map(reducer => (<ReducerItem key={reducer.id} reducer={reducer} />))}
      </ul>
    </LayoutBody>
  )
}

function ReducerItem({ reducer }: { reducer: Reducer }) {
  const { accountName, streamName } = useParams()
  return (<li>
    <Link to={`${appPath({ accountName, streamName, reducerName: reducer.name })}`}>{reducer.name}</Link>
  </li>)
}