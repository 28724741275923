import { useParams } from "react-router-dom";
import { LayoutBody } from "./Root";

import { javascript } from "@codemirror/lang-javascript";
import { EditorState } from "@codemirror/state";
import { EditorView } from "@codemirror/view";
import { basicSetup } from "codemirror";
import { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { appPath } from "./routes";
import { api, currentReducerState } from "./state";

export function ReducerApp() {
  const { accountName, streamName, reducerName } = useParams();
  if (!accountName || !streamName || !reducerName) {
    throw new Error("Invalid path");
  }
  const editorRef = useRef<HTMLDivElement>(null);
  const [reducer, setReducer] = useRecoilState(currentReducerState([accountName, streamName, reducerName]));
  const source = reducer?.current_version?.source || "";
  const sourceRef = useRef<string>(source);
  const updateFn = EditorView.updateListener.of(update => {
    if (sourceRef.current === update.state.doc.toString()) {
      return
    }
    sourceRef.current = update.state.doc.toString();
    // TODO incremental parsing
    // const tree = parser.parse()
  })

  const editorState = EditorState.create({
    doc: source,
    extensions: [
      basicSetup,
      javascript(),
      updateFn
    ]
  })
  useEffect(() => {
    if (editorRef.current) {
      const view = new EditorView({
        state: editorState,
        parent: editorRef.current,
      })
      return () => { view.dom.remove() }
    }
  }, [editorState])

  const [state, setState] = useState<any>(null);
  useEffect(() => {
    fetch(appPath({ accountName, streamName, reducerName }), {
    }).then(res => {
      if (res.status === 204) {
        setState(undefined)
        return
      }
      return res.json()
    }).then(res => setState(res));
  })

  const [showEditReducerInput, setShowEditReducerInput] = useState(false)

  const editReducerName = () => {
    setShowEditReducerInput(true)
  }
  const saveReducerName = async () => {
    const resp = await api(`/reducers${appPath({ accountName, streamName, reducerName })}`, {
      method: 'PATCH',
      body: { name: inputReducerName }
    })
    setReducer(await resp.json())
    setShowEditReducerInput(false)
  }
  const [inputReducerName, setInputReducerName] = useState(reducerName)

  const saveReducerSource = async () => {
    const resp = await api(`/versions${appPath({ accountName, streamName, reducerName })}`, {
      method: 'POST',
      body: { source: sourceRef.current }
    })
    const version = await resp.json()
    setReducer({ ...reducer, current_version: version })
  }
  const handleDelete = async () => {
    const resp = await api(`/reducers${appPath({ accountName, streamName, reducerName })}`, {
      method: 'DELETE'
    })
    if (resp.ok) {
      window.location.href = `${appPath({ accountName, streamName })}/reducers`
    } else {
      alert('Error deleting reducer')
    }
  }

  const [dryRunResult, setDryRunResult] = useState<{
    state: string,
    logs: { timestamp: string, line: string }[],
    error: string,
  } | null>(null)

  const dryRun = async () => {
    const resp = await api(`/eval${appPath({ accountName, streamName })}`, {
      method: 'POST',
      body: { source: sourceRef.current }
    })
    const result = await resp.json()
    setDryRunResult(result)
  }

  const restartReducer = async () => {
    const resp = await api(`/reducers${appPath({ accountName, streamName, reducerName })}/status`, {
      method: 'POST',
      body: { status: 'queued' }
    })
    if (!resp.ok) {
      alert('Error restarting reducer')
    }
  }

  return (
    <LayoutBody>
      <div style={{ height: 36 }}>
        {showEditReducerInput ?
          <>
            <input placeholder="reducer name"
              value={inputReducerName}
              onChange={(e) => setInputReducerName(e.target.value)}
              style={{ fontSize: 22, fontWeight: 600 }} />
            <button onClick={saveReducerName}>Save</button>
          </> :
          <div style={{ fontSize: 22, fontWeight: 600 }} onClick={editReducerName}>{reducerName}</div>}
      </div>
      <button onClick={handleDelete}>Delete</button>
      <div ref={editorRef}></div>
      <button onClick={dryRun}>Test</button> <button onClick={saveReducerSource}>Save</button>
      <div style={{ marginTop: 12 }}>
        <div style={{ fontSize: 22, fontWeight: 600 }} >State</div>
        {state ? <div>{state}</div> : <pre>undefined</pre>}
      </div>
      <div style={{ marginTop: 12 }}>
        <div style={{ fontSize: 22, fontWeight: 600 }} >Status</div>
        <div>{reducer.status} {reducer.status == 'stopped' && <button onClick={restartReducer}>Restart</button>}</div>

      </div>

      {dryRunResult && (
        <div style={{ marginTop: 12 }}>
          <div style={{ fontSize: 22, fontWeight: 600 }}>Dry run</div>
          <div>State: </div>
          <div>{dryRunResult.state}</div>
          <br />
          <div>Logs:</div>
          <div>
            {dryRunResult?.logs?.map(log => (
              <span key={log.timestamp}>
                <span style={{ fontWeight: 'bold' }}>{log.timestamp}</span>
                &nbsp;
                <span>{log.line}</span>
              </span>
            ))}
          </div>
        </div>
      )}

    </LayoutBody>
  )
}
