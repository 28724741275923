import { Link, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { orgMembersState, streamsListState } from "./state";

export function AccountApp() {
    const { accountName } = useParams();
    const streams = useRecoilValue(streamsListState(accountName!))
    const members = useRecoilValue(orgMembersState(accountName!))
    return (
        <>
            <div>Account: {accountName}</div>
            <br />
            <Link to={`/${accountName}/new`}>New Stream</Link>
            <br />
            <br />
            <div>Streams</div>
            <div>
                {streams ?
                    streams.map(stream => <div key={stream.name}><Link to={`/${accountName}/${stream.name}`}>{stream.name}</Link></div>)
                    : <div>No streams</div>}
            </div>
            <br />
            <div>Members</div>
            <div>
                {members ? members.map(member => <div key={member.id}>{member.name || member.id}: {member.member_role}</div>) : <div>No members</div>}
            </div>
        </>
    )
}
