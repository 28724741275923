import { Link, NavLink, useParams } from "react-router-dom";
import styled from "styled-components";

function StreamHeader(props: { className?: string, isReducer?: boolean }) {
  const { className, isReducer } = props;
  return (
    <div className={className}>
      <Title />
      <StreamMenu isReducer={isReducer} />
    </div>
  )
}

export const StyledStreamHeader = styled(StreamHeader)`
display: flex; 
flex-direction: column; 
background-color: #F0F0F0; 
height: 100px; 
padding-top: 30px; 
padding-left: 20px; 
padding-right: 20px; 
filter: drop-shadow(1px 1px 1px #D3D3D3);
`

function Title() {
  const { accountName, streamName } = useParams();
  return (
    <div className="stream-page-title" style={{
      fontSize: '28px',
      fontWeight: 600,
      marginBottom: 20,
      flexGrow: 1,
    }}>
      <span style={{ paddingRight: '8px' }}>
        <img src="/logo.png" alt="Pipet" style={{ height: "20px", width: "20px" }} />
      </span>
      <Link to={`/${accountName}`} >{accountName}</Link>
      <span style={{
        color: '#676767',
        paddingLeft: '8px',
        paddingRight: '8px'
      }}>/</span>
      <Link to={`/${accountName}/${streamName}`}>{streamName}</Link>
    </div>
  )
}


const StyledNavLink = styled(NavLink)`
border-bottom-style: solid;
border-bottom-color: #F0F0F0;
border-bottom-width: 2px;
padding-left: 8px;
padding-right: 8px;
padding-bottom: 4px;
text-decoration: none;
color: #4E4E4E;

&:visited {
  text-decoration: none;
  color: #4E4E4E;
}
&.active {
  border-bottom-color: #FF7D04
}
&:hover {
  border-bottom-color: #D3D3D3
}
&.active:hover {
  border-bottom-color: #FF7D04
}
`

function StreamMenu(props: { isReducer?: boolean }) {
  const { isReducer } = props;
  const { accountName, streamName } = useParams();
  const baseStreamPath = `/${accountName}/${streamName}`

  return (
    <div style={{ fontSize: 18, fontWeight: 500, color: '#4E4E4E', display: 'flex', paddingBottom: 0, marginBottom: 0 }}>
      <StyledNavLink end to={baseStreamPath} >Events</StyledNavLink>
      <StyledNavLink to={baseStreamPath + "/form"} >Form</StyledNavLink>
      <StyledNavLink className={isReducer ? 'active' : undefined} to={baseStreamPath + "/reducers"}>Reducers</StyledNavLink>
      <StyledNavLink to={baseStreamPath + "/settings"} >Settings</StyledNavLink>
    </div >
  )
}
