import { useState } from "react";
import { useParams } from "react-router-dom";
import { useResetRecoilState } from "recoil";
import { api, streamsListState } from "./state";

export function CreateStream() {
    const { accountName } = useParams();
    const [streamName, setStreamName] = useState('');
    const [readAccess, setReadAccess] = useState('public');
    const [writeAccess, setWriteAccess] = useState('public');
    const resetStreams = useResetRecoilState(streamsListState(accountName!))
    const createStream = async () => {
        const resp = await api(`/streams/${accountName}`, {
            method: "POST",
            body: { name: streamName, read_access: readAccess, write_access: writeAccess }
        })
        if (resp.status === 201) {
            resetStreams()
        }
        const stream = await resp.json()
        window.location.href = `/${accountName}/${stream.name}`
    }
    return (
        <div>
            <div>Create new stream</div>
            <label>Name:</label><input type="text" value={streamName} onChange={e => setStreamName(e.target.value)} />
            <div>
                <label>Read access</label>
                <select value={readAccess} onChange={e => setReadAccess(e.target.value)}>
                    <option value="public">public</option>
                    <option value="hmac">HMAC</option>
                    <option value="private">private</option>
                </select>
            </div>
            <div>
                <label>Write access</label>
                <select value={writeAccess} onChange={e => setWriteAccess(e.target.value)}>
                    <option value="public">public</option>
                    <option value="hmac">HMAC</option>
                    <option value="private">private</option>
                </select>
            </div>
            <div>
                <button onClick={createStream}>Submit</button>
            </div>
        </div>
    )
}